<template>
  <base-info-card title="Recent Articles">
    <v-list class="py-0">
      <template v-for="(article, i) in articles">
        <v-list-item
          :key="i"
          class="px-0"
          three-line
        >
          <base-img
            :src="article.src"
            class="mr-4"
            color="grey lighten-1"
            height="72"
            max-width="72"
          />

          <v-list-item-content>
            <v-list-item-subtitle
              class="text-uppercase subtitle-2 font-weight-bold text--primary mb-n2"
              v-text="article.title"
            />

            <v-list-item-subtitle
              class="font-weight-bold"
              v-text="article.date"
            />
          </v-list-item-content>
        </v-list-item>

        <v-divider :key="`divider-${i}`" />
      </template>
    </v-list>
  </base-info-card>
</template>

<script>
  export default {
    name: 'NewsRecentArticles',

    data: () => ({
      articles: [
        {
          date: '',
          title: 'Welcome Colin, Our Second Son',
          src: 'https://images.unsplash.com/photo-1511948374796-056e8f289f34?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&auto=format&fit=crop&w=1350&q=80',
        },
        {
          date: '',
          title: 'Welcome Kelton to the World',
          src: 'https://images.unsplash.com/photo-1470116945706-e6bf5d5a53ca?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxzZWFyY2h8NHx8cHJlZ25hbmN5fGVufDB8fDB8&auto=format&fit=crop&w=500&q=60',
        },
        {
          date: '',
          title: 'I Am Becoming a Father!!!',
          src: 'https://images.unsplash.com/photo-1527620743113-fac8dd0f8d17?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1267&q=80',
        },
      ],
    }),
  }
</script>
